import clsx from 'clsx'
import React, { forwardRef } from 'react'

import * as styles from './button.module.scss'

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export const Button = forwardRef<HTMLButtonElement | null, ButtonProps>((props, ref) => {
  return (
    <button
      ref={ref}
      className={clsx([
        styles.button,
        props.className
      ])}
    >
      {props.children}
    </button>
  )
})