import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as styles from './bio.module.scss'
import { Button } from '../button'

interface BioProps {
  avatar: string,
  name: string,
  description?: string,
  socialMediaUrl?: string,
  size?: `small` | `large`
}

export const Bio: React.FC<BioProps> = ({
  avatar,
  name,
  description,
  socialMediaUrl,
  size
}) => {
  const [width, setWidth] = useState(
    typeof window !== "undefined"
      ? window.innerWidth
      : 0
  )

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener(`resize`, handleResize)
    return () => {
      window.removeEventListener(`resize`, handleResize)
    }
  }, [])

  if (size === `large` && width > 996) return (
    <div className={clsx([styles.bio, styles.large])}>
      <div>
        <img src={avatar} alt={name} />
      </div>
      <div className="d-flex align-items-center">
        <div>
          <b>{name}</b>
          <p>{description}</p>
        </div>
        <div>
          <a
            href={socialMediaUrl}
            target="_blank"
            rel="noopener"
            title={`Siga ${name} nas redes sociais.`}
          >
            <Button>
              Seguir
            </Button>
          </a>
        </div>
      </div>
    </div>
  )

  return (
    <div className={styles.bio}>
      <div>
        <img src={avatar} alt={name} />
      </div>
      <div>
        <b>
          <span>{name}</span>
          <a
            href={socialMediaUrl}
            target="_blank"
            rel="noopener"
            title={`Siga ${name} nas redes sociais.`}
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        </b>
        <p>{description}</p>
      </div>
    </div>
  )
}

Bio.defaultProps = {
  size: `small`
}