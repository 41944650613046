import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'

import * as styles from './share.module.scss'

interface ShareProps {
  url: string
}

const getLinkedinLink = (url: string) =>
  `http://www.linkedin.com/shareArticle?mini=true&url=${url}`

const getFacebookLink = (url: string) =>
  `https://www.facebook.com/sharer/sharer.php?u=${url}`

const getTwitterLink = (url: string) =>
  `http://twitter.com/share?url=${url}`

const openPopup = (url: string) =>
  window.open(
    url,
    `_blank`,
    `width=640,height=480`
  )

export const Share: React.FC<ShareProps> = ({ url }) => {

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    url: string
  ) => {
    e.preventDefault()
    openPopup(url)
  }

  return (
    <div>
      <div className="d-flex">

        <a
          className={styles.shareIcon}
          href={getLinkedinLink(url)}
          target="_blank"
          rel="noopener"
          title="Compartilhar no LinkedIn"
          onClick={(e) => handleClick(e, getLinkedinLink(url))}
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>

        <a
          className={styles.shareIcon}
          href={getFacebookLink(url)}
          target="_blank"
          rel="noopener"
          title="Compartilhar no Facebook"
          onClick={(e) => handleClick(e, getFacebookLink(url))}
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>

        <a
          className={styles.shareIcon}
          href={getTwitterLink(url)}
          target="_blank"
          rel="noopener"
          title="Compartilhar no Twitter"
          onClick={(e) => handleClick(e, getTwitterLink(url))}
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>

      </div>
    </div>
  )
}