import React from 'react'
import { PageProps, Link, graphql } from 'gatsby'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

import './blogPost.scss'
import avatar from '../images/adiel.jpg'

import { SEO } from '../components/seo'
import { Layout } from '../components/layout'
import { Heading } from '../components/heading'
import { PostCategory } from '../components/post'
import { Bio } from '../components/bio'
import { Share } from '../components/share'

interface Post {
  excerpt: string
  html: string
  fields: {
    slug: string
    readingTime: {
      minutes: number
    }
  }
  frontmatter: {
    date: string
    title: string
    description: string
    thumbnail: string
    category: string
    authorName: string
    authorSocialMedia: string
    authorResume: string
    authorPhoto: string
  }
}

interface BlogPostTemplateProps extends PageProps {
  data: {
    site: any
    markdownRemark: Post
    previous?: Post
    next?: Post
  }
}

const BlogPostTemplate: React.FC<BlogPostTemplateProps> = ({
  data,
  location,
}) => {
  const post = data.markdownRemark
  const { previous, next } = data
  const readingTime = Math.round(post.fields.readingTime.minutes)

  return (
    <Layout location={location}>
      <SEO
        title={`Blog: ${post.frontmatter.title}`}
        description={post.frontmatter.description || post.excerpt}
        meta={[
          {
            property: `og:image`,
            content: post.frontmatter.thumbnail,
          },
          {
            property: `og:url`,
            content: location.href,
          },
        ]}
      />
      <Heading
        message={post.frontmatter.date}
        title={post.frontmatter.title}
        backgroundUrl={post.frontmatter.thumbnail}
        addOverlay={!!post.frontmatter.thumbnail}
      />
      <article
        className={clsx([`container`, `blogPostTemplate`])}
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="mb-5">
          <PostCategory
            category={post.frontmatter.category}
            query={post.frontmatter.category}
          />
          {/* <h1 itemProp="headline">{post.frontmatter.title}</h1> */}
          <div className="d-flex justify-content-between">
            <Bio
              name={post.frontmatter.authorName}
              avatar={post.frontmatter.authorPhoto}
              description={`${post.frontmatter.date} · ${readingTime} min de leitura`}
              socialMediaUrl={post.frontmatter.authorSocialMedia}
            />
            <Share url={location.href} />
          </div>
        </header>
        <section
          className="mb-5"
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <footer>
          <Bio
            size="large"
            name={post.frontmatter.authorName}
            avatar={post.frontmatter.authorPhoto}
            description={post.frontmatter.authorResume}
            socialMediaUrl={post.frontmatter.authorSocialMedia}
          />
          <hr className="mt-5" />
          <div className="row">
            <div className="col-lg-6">
              {previous && (
                <Link className="prevPost" to={previous.fields.slug} rel="prev">
                  <img
                    className="thumbnail"
                    src={previous.frontmatter.thumbnail}
                    alt={previous.frontmatter.title}
                  />
                  <div>
                    <small className="postLink">
                      <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                      <span>Post anterior</span>
                    </small>
                    <h4>{previous.frontmatter.title}</h4>
                  </div>
                </Link>
              )}
            </div>
            <div className="col-lg-6">
              {next && (
                <Link className="nextPost" to={next.fields.slug} rel="next">
                  <div>
                    <small className="postLink">
                      <span>Próximo post</span>
                      <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
                    </small>
                    <h4>{next.frontmatter.title}</h4>
                  </div>
                  <img
                    className="thumbnail"
                    src={next.frontmatter.thumbnail}
                    alt={next.frontmatter.title}
                  />
                </Link>
              )}
            </div>
          </div>
        </footer>
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD [de] MMMM [de] YYYY", locale: "pt-BR")
        description
        thumbnail
        category
        authorName
        authorSocialMedia
        authorResume
        authorPhoto
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        thumbnail
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        thumbnail
      }
    }
  }
`
